.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.bottom-bar {
  position: fixed;
  bottom: 0;
  /* width: 100%; */
  width: inherit;
  background-color: #f2f2f2;
  /* border-top: 1px solid #ccc; */
  /* padding: 10px; */
}
/* 
.bottom-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.bottom-bar li {
  padding: 10px;
} */


.btn-primary {
  background-color: #0C2978;
  border-color: #0C2978;
}

.text-primary {
  color: #0C2978;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.border-primary {
  border-color: #0C2978 !important;
}

.bg-primary {
  background-color: #0C2978 !important;
}

.text-primary {
  color: #0C2978 !important;
}

.app-scroll {
  height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 70px;
}